import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: () => import("./components/Admin/Login"),
      props: true,
      meta: {
        hideNavbar: true,
      },
    },
    {
      path: "/admin",
      component: () => import("./components/Admin/Admin"),
      children: [
        {
          path: "",
          alias: "usuarios",
          name: "usuarios",
          props: true,
          component: () => import("./components/Admin/Usuarios"),
        },
        {
          path: "clasif",
          name: "clasif",
          component: () => import("./components/Admin/Clas_art"),
        },
      ],
    },
    {
      path: "/stock",
      component: () => import("./components/Stock/Stock"),
      children: [
        {
          path: "",
          alias: "allcodes",
          name: "allcodes",
          component: () => import("./components/Stock/CodesList"),
          props: true,
        },
        {
          path: "gallery",
          name: "Gallery",
          component: () => import("./components/Stock/CodesGallery"),
          props: true,
        },
        {
          path: "dashboard",
          name: "dashboard",
          component: () => import("./components/Stock/Dashboard"),
        },
      ],
    },
    {
      path: "/cotizacion",
      name: "cotizacion",
      component: () => import("./components/Gastos/Cotizacion"),
    },
    {
      path: "/cotizaciones",
      name: "cotizaciones",
      component: () => import("./components/Gastos/Cotizaciones"),
    },
    {
      path: "/ppto-serv",
      name: "ppto-serv",
      component: () => import("./components/Gastos/PPTO_serv"),
    },
    {
      path: "/gasto",
      component: () => import("./components/Gastos/Gasto"),
      children: [
        {
          path: "",
          name: "gasto",
          component: () => import("./components/Gastos/Gastogen"),
        },
        {
          path: "bodega",
          name: "bodega",
          component: () => import("./components/Gastos/Bodega"),
        },
        {
          path: "ordcompra",
          name: "ordcompra",
          component: () => import("./components/Gastos/OrdenCompra"),
        },
        {
          path: "spclist",
          name: "spclist",
          component: () => import("./components/Gastos/SPClist"),
        },
        {
          path: "infsema",
          name: "infsema",
          component: () => import("./components/Gastos/InfSema"),
        },
        {
          path: "presupuesto",
          name: "presupuesto",
          component: () => import("./components/Gastos/Presupuesto"),
        },

        {
          path: "pesokilo",
          name: "pesokilo",
          component: () => import("./components/Gastos/RendxKG"),
        },
      ],
    },
  ],
});
