<template>
  <v-app>
    <v-app-bar
      v-if="!$route.meta.hideNavbar"
      app
      color="red accent-4"
      dark
      shrink-on-scroll
      fade-img-on-scroll
      flat
      src="https://www.ariztia.com/wp-content/themes/ariztia-theme_2020/assets/img/barra-pasto.jpg"
    >
      <v-menu
        v-if="!tercero"
        :nudge-width="150"
        offset-x
        transition="slide-x-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list color="pink lighten-5" nav shaped>
          <v-list-item link v-if="verpai" to="/gasto">
            <v-list-item-icon><v-icon>mdi-finance</v-icon></v-list-item-icon>
            Gastos
          </v-list-item>
          <v-list-item link to="/gasto/spclist">
            <v-list-item-icon><v-icon>mdi-warehouse</v-icon></v-list-item-icon>
            SPC
          </v-list-item>
          <v-list-item link to="/stock">
            <v-list-item-icon><v-icon>mdi-warehouse</v-icon></v-list-item-icon>
            Stock
          </v-list-item>
          <v-list-item link v-if="verrole" to="/admin">
            <v-list-item-icon><v-icon>mdi-cogs</v-icon></v-list-item-icon>
            Config
          </v-list-item>
          <v-list-item link @click="logOut()">
            <v-list-item-icon
              ><v-icon>mdi-account-off-outline</v-icon></v-list-item-icon
            >
            Salir
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title>
        Plataforma de Existencias y Presupuestos
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-img
        contain
        max-width="15%"
        max-height="100%"
        src="https://www.ariztia.com/wp-content/themes/ariztia-theme_2020/assets/img/logo.svg"
      ></v-img>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer color="red accent-4" dark padless>
      <v-col class="text-center" cols="12">
        <strong>Ariztía - Depto. Mantenimiento</strong>
        <br />{{ new Date().getFullYear() }} — v0.8.5α — @BenAliSoft
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "app",
  data: () => ({
    drawer: false,
    verpai: false,
    verrole: false,
    tercero: false,
  }),
  watch: {
    "$store.state.auth.status.loggedIn": function() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (user) {
        (this.verpai = user.planta.includes("paico")),
          (this.verrole = user.roles.includes("ROLE_HYPERVISOR"));
        this.tercero = user.roles.includes("ROLE_TERCERO");
      }
    },
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      (this.verpai = user.planta.includes("paico")),
        (this.verrole = user.roles.includes("ROLE_HYPERVISOR"));
      this.tercero = user.roles.includes("ROLE_TERCERO");
    }
  },
  computed: {},
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (!user) {
      this.$router.push("/login");
    }
  },
};
</script>
